import React, { useState, useRef, useEffect } from 'react';
import { Paper, Typography, Badge, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar, PickersDay, DayCalendarSkeleton } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

// Function to simulate server fetch
function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      // Get the last 5 days from today
      const today = dayjs();
      const daysToHighlight = Array.from({ length: 5 }, (_, index) => today.subtract(index, 'day').date());

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException('aborted', 'AbortError'));
    };
  });
}


// ServerDay component to add badges to highlighted days
function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={{
        borderRadius: '50%', // Make the selection circular
        backgroundColor: isSelected ? '#DDEDF1' : 'transparent', // Highlight color for selected day
        color: isSelected ? '#fff' : undefined, // Change text color when selected
        '&:hover': {
          backgroundColor: isSelected ? '#FF8C00' : '#e0e0e0', // Hover effect
        },
      }}
    />
  );
}

const CalendarComponent = () => {
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, { signal: controller.signal })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  useEffect(() => {
    fetchHighlightedDays(dayjs());
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: '9px', padding: 2 }}>
      {/* Calendar */}
      <Paper
        elevation={1}
        sx={{
          borderRadius: '9px',
          padding: 1,
          // backgroundColor: '#F6FAFB',
          marginBottom: 2,
          marginTop: -4,
          marginLeft: "-19px",
          boxShadow: 'none',
          transform: 'scale(0.8)',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            defaultValue={dayjs()}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
            sx={{
              width: '100%',
              maxWidth: '400px',
              minWidth: '250px',
              height: '280px',
              borderRadius: '9px',
              backgroundColor: '#F6FAFB',
            }}
          />
        </LocalizationProvider>
      </Paper>

      {/* Streak Box */}
      <Paper
        elevation={0}
        sx={{
          height: 120,
          borderRadius: '9px',
          backgroundColor: '#FFEB8C',
          padding: 2, marginTop: "-40px"
        }}
      >
        <Typography  sx={{ color: '#FFA600', fontWeight: "800" }}>
          Suprava,{' '}
          <span style={{ fontWeight: 'normal', fontWeight: "700", fontStyle: "italic" }}>You are on Track!</span>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Keep the momentum going and watch your streak grow.
        </Typography>
      </Paper>
    </Paper>
  );
};

export default CalendarComponent;
