import React from 'react';
import { Paper, Box, Grid, Avatar, Typography, IconButton, Button, Divider } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const articles = [
  {
    position: 'Software Engineer',
    companyType: 'Corporate',
    location: 'Bangalore, India',
    jobType: 'Internship',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    time: '3 Hours ago',
  },
  {
    position: 'Product Manager',
    companyType: 'Corporate',
    location: 'San Francisco, USA',
    jobType: 'Full-Time',
    description:
      'Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod.',
    time: '5 Hours ago',
  },
  {
    position: 'Data Scientist',
    companyType: 'Corporate',
    location: 'London, UK',
    jobType: 'Contract',
    description:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
    time: '1 Day ago',
  },
  {
    position: 'UX Designer',
    companyType: 'Corporate',
    location: 'Berlin, Germany',
    jobType: 'Freelance',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
    time: '2 Days ago',
  },
  {
    position: 'Marketing Specialist',
    companyType: 'Corporate',
    location: 'Toronto, Canada',
    jobType: 'Part-Time',
    description:
      'Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    time: '1 Week ago',
  },
  {
    position: 'Backend Developer',
    companyType: 'Corporate',
    location: 'Austin, USA',
    jobType: 'Internship',
    description:
      'Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra augue.',
    time: '2 Weeks ago',
  },
];

const Feeds = () => {
  return (
    <>
      {/* Articles Section */}
      <Paper elevation={0} sx={{ borderRadius: '9px', padding: 2 }}>
        {articles.map((article, index) => (
          <Box key={index} sx={{ mb: 1, padding: '10px' }}>
            {/* Job Header */}
            <Grid container spacing={2}>
              {/* Avatar and Position */}
              <Grid item xs={1} style={{ marginRight: '20px' }}>
                <Avatar sx={{ width: 40, height: 40 }} />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" fontWeight="bold">
                  {article.position}
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <BusinessCenterIcon fontSize="small" />
                    <Typography variant="caption" ml={0.5}>
                      {article.companyType}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LocationOnIcon fontSize="small" />
                    <Typography variant="caption" ml={0.5}>
                      {article.location}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">{article.jobType}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton>
                  <BookmarkBorderIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* Job Description */}
            <Paper
              elevation={0}
              sx={{
                borderRadius: '9px',
                padding: 2,
                mt: 2,
                backgroundColor: '#f9f9f9',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {article.description}
              </Typography>
              {/* Footer: Time and Actions */}
            <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
              <Typography variant="caption" color="text.secondary">
                {article.time}
              </Typography>
              <Box>
                <IconButton>
                  <SearchIcon
                    sx={{
                      backgroundColor: '#e0e7ff',
                      borderRadius: '50%',
                      padding: '8px',
                    }}
                  />
                </IconButton>
                <IconButton>
                  <ArrowForwardIcon
                    sx={{
                      backgroundColor: '#e0e7ff',
                      borderRadius: '50%',
                      padding: '8px',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            </Paper>

            

            {/* Divider after each article */}
            <Divider sx={{ my: 2 }} />
          </Box>
        ))}
      </Paper>

     {/* Internships Section */}
<Paper elevation={0} sx={{ height: 'auto', mt: 2, borderRadius: '9px' }}>
  <Typography variant="h6" sx={{ padding: 2, fontWeight: "600" }}>
    Looking for Internships?
  </Typography>
  <Box sx={{ overflowX: 'auto', display: 'flex', padding: 2 }}>
    {/* Internship Listings */}
    {[1, 2, 3].map((_, index) => (
      <Box key={index} sx={{ minWidth: 250, marginRight: 2 }}>
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            textAlign: 'center',
            borderRadius: '9px',
            backgroundColor: '#F5F7F6',
          }}
        >
          <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
            Company name
          </Typography>
          <Typography variant="body2" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
          <Button variant="contained" sx={{ mt: 2, width: '100%' }}>
            Apply
          </Button>
        </Paper>
      </Box>
    ))}
  </Box>
</Paper>

    </>
  );
};

export default Feeds;
