import React from 'react';
import { Stack, Typography, Paper, Grid, Box } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Image from 'next/image'; // Importing Next.js Image component

const WhatsappChannelComponent = () => {
  // Array of notifications with image paths and external links
  const notifications = [
    {
      title: 'Job Postings',
      message: 'New job opportunities in your field',
      time: '09:18',
      count: 9,
      imageUrl: '/assets/background/jobs.png',
      link: 'https://chat.whatsapp.com/EbG7BAAJYgJHEwUFEx80O4', // External link
    },
    {
      title: 'Events Happening',
      message: 'Do not miss out',
      time: '09:18',
      count: 9,
      imageUrl: '/assets/background/events.png',
      link: 'https://chat.whatsapp.com/EvrpmyAfhfKB3M8hlzpXKv',
    },
    {
      title: 'AIR Notes',
      message: 'Access the latest study materials',
      time: '09:18',
      count: 9,
      imageUrl: '/assets/background/notes.png',
      link: 'https://chat.whatsapp.com/IJwuKOzfPPh6S1cXXGKZbh',
    },
    {
      title: 'Alumni Network',
      message: 'Stay connected',
      time: '09:18',
      count: 9,
      imageUrl: '/assets/background/network.png',
      link: 'https://chat.whatsapp.com/LrUJYYbSN1iCrPCtjmEb4m',
    },
    {
      title: 'AIR Classes',
      message: 'Your next AIR class starts soon.',
      time: '09:18',
      count: 9,
      imageUrl: '/assets/background/class.png',
      link: 'https://chat.whatsapp.com/La6ysC28jlo5fw2ddvXqff',
    },
  ];

  return (
    <div>
      {/* Whatsapp Channels Heading */}
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        {/* Green Live Icon */}
        <FiberManualRecordIcon sx={{ color: 'green', fontSize: 16 }} />

        {/* Whatsapp Channels Text */}
        <Typography sx={{ fontWeight: 'bold' }}>
          Whatsapp Channels
        </Typography>
      </Stack>

      {/* Notifications */}
      {notifications.map((item, index) => (
        <Paper
          elevation={0}
          key={index}
          sx={{
            height: 80,
            mt: 2,
            borderRadius: '9px',
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#F6FAFB', // Background color on hover
            },
          }}
          onClick={() => window.open(item.link, '_blank')} // Opens the external link in a new tab
        >
          <Grid container spacing={2} alignItems="center">
            {/* Image in place of Avatar */}
            <Grid item>
              <Image
                src={item.imageUrl}
                alt={item.title}
                width={40}
                height={40}
                style={{ borderRadius: '50%' }}
              />
            </Grid>

            {/* Title and Message */}
            <Grid item xs>
              <Typography variant="body1" fontWeight="bold">
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.message}
              </Typography>
            </Grid>

            {/* Time and Count */}
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                {item.time}
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#F0F0F0',
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 1,
                }}
              >
                <Typography variant="body2" fontWeight="bold">
                  {item.count}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
};

export default WhatsappChannelComponent;
